import React, { useEffect, useState } from "react";
import { chooseSlot, GetInterviewSlots } from "../../../WebCalls";
import { InterviewSlots } from "../../../Models";
import ConfirmModal from "../../shared/ConfirmModal";
import Notification from "../../shared/Notification";
import { useParams } from "react-router-dom";
import { Spinner } from "../../shared/Spinner";

type RouteParams = {
  candidateId: string;
  roleStatusId: string;
};
const PickSlots = () => {
  const [slots, setSlots] = useState<InterviewSlots[]>([]);
  const [confirmation, setConfirmation] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<InterviewSlots>();
  const [message, setMessage] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState<{
    title: string;
    type: "success" | "error" | "info";
    description: string;
  }>({
    title: "",
    type: "success",
    description: "",
  });
  const { candidateId, roleStatusId } = useParams<RouteParams>();
  
  useEffect(() => {
    getSlots();
  }, []);
  const getSlots = async () => {
      setLoading(true);
    try {
      
      const res = await GetInterviewSlots(candidateId, roleStatusId);
      if (typeof res === "string") {
       
        setMessage(res);
      } else {
        setSlots(res);
        setMessage("");
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleSlotClick = (slot: InterviewSlots) => {
    setSelectedSlot(slot);
    setConfirmation(true);
  };
  const handleConfirmSlot = async () => {
    if (selectedSlot != null) {
      try {

        setLoading(true);
        setConfirmation(false);
        const res = await chooseSlot(
          candidateId,
          selectedSlot.id,
          roleStatusId,
        );
        setLoading(false);
        setNotification({
          title: res.type === "success" ? "Slot Picked Successfully" : "Slot not Picked.",
          type: res.type,
          description: res.description,
        });
        setShowNotification(true);
        await getSlots();
      } catch {
        setConfirmation(false);
        
        setNotification({
          title: "Error",
          type: "error",
          description: "Something Went Wrong. Please try again.",
        });
        await getSlots();
        
      }
    }
  };

  return (
    <div className=" min-h-screen bg-white dark:bg-slate-900 ">
      <div>
        <header className="bg-gray-50 dark:bg-slate-800 py-8">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between flex-wrap">
            <h1 className="mt-2 text-2xl font-bold leading-7 text-gray-700 sm:text-3xl sm:tracking-tight dark:text-gray-300">
              Pick Your Slot
            </h1>
          </div>
        </header>
      </div>
      <div className="flex flex-col items-center">
        {loading ? (
            <Spinner h="mt-10 h-14" w="w-14"/>
        ):message ? (
          <div className="mt-10  p-4 bg-blue-100 border border-blue-400 text-blue-700 rounded-lg shadow-md">
            {message}
          </div>
        ) : (
          
          <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-2 sm:gap-4">
            {slots.map((slot) => (
              <button
                key={slot.id}
                onClick={() => handleSlotClick(slot)}
                disabled={!slot.isAvailable}
                className={`p-2 sm:p-3 border rounded-lg shadow-md transform transition-transform duration-300 ${
                  slot.isAvailable
                    ? "bg-white dark:bg-slate-800 hover:bg-blue-100 border-blue-400 hover:scale-105  text-gray-700 dark:text-gray-300 hover:dark:text-gray-300 dark:hover:bg-slate-600 "
                    : "bg-gray-200 cursor-not-allowed border-gray-300 text-gray-500 dark:text-gray-500 dark:bg-gray-600"
                }`}
              >
                <div className="text-xs sm:text-sm font-semibold">
                  {new Date(slot.startTime).toLocaleDateString("en-IN", {
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  })}
                </div>
                <div className="text-xs sm:text-sm font-semibold  mt-1 ">
                  {new Date(slot.startTime).toLocaleTimeString("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                  {" - "}
                  {new Date(slot.endTime).toLocaleTimeString("en-IN", {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
                <div
                  className={`mt-1 text-xs ${
                    slot.isAvailable ? "text-green-600" : "text-gray-500"
                  }`}
                >
                  {slot.isAvailable ? "Available" : "Unavailable"}
                </div>
              </button>
            ))}
          </div>
        )}
      </div>
      <ConfirmModal
        type="danger"
        title="Confirmation ?"
        open={confirmation}
        setOpen={setConfirmation}
        description={
          <div>
            <p>Are you sure you want to confirm this slot?</p>
            <p className="mt-2 text-sm font-semibold">
              Selected Time:{" "}
              {selectedSlot &&
                `${new Date(selectedSlot.startTime).toLocaleDateString(
                  "en-IN",
                  {
                    weekday: "short",
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                  },
                )} at ${new Date(selectedSlot.startTime).toLocaleTimeString(
                  "en-IN",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  },
                )} - ${new Date(selectedSlot.endTime).toLocaleTimeString(
                  "en-IN",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  },
                )}`}
            </p>
          </div>
        }
        buttonContents={[
          {
            buttonId: 1,
            buttonLabel: "Confirm",
            buttonAction: handleConfirmSlot, 
          },
          {
            buttonId: 2,
            buttonLabel: "Cancel",
            buttonAction: () => setConfirmation(false),
            isOutline: true,
          },
        ]}
      />
      <Notification
        title={notification.title}
        type={notification.type}
        description={notification.description}
        show={showNotification}
        setShow={setShowNotification}
      />
    </div>
  );
};

export default PickSlots;
